<!--
 * @Author: lidd@belink.com
 * @Date: 2024-01-29 23:07:16
 * @LastEditors: lidd@belink.com
 * @LastEditTime: 2024-01-31 22:11:51
 * @Description: 视频
-->
<template>
  <!-- <h1>全纪录</h1> -->
  <!-- <p>逝者已矣 生者如斯</p> -->
  <div class="video-box">
    <h1>视频已下线</h1>
    <!-- <div class="video-list" v-for="(i, index) in sourceList" :key="index">
      <video controls>
        <source :src="i.url" type="video/mp4" />
        你的浏览器不支持.
      </video>
      <p>{{ i.title }}</p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      sourceList: [
        {
          url: "https://shengzhiba.oss-cn-beijing.aliyuncs.com/lidd/1.mov",
          title: "第一集",
        },
        {
          url: "https://shengzhiba.oss-cn-beijing.aliyuncs.com/lidd/2.mov",
          title: "第二集",
        },
        {
          url: "https://shengzhiba.oss-cn-beijing.aliyuncs.com/lidd/3.mov",
          title: "第三集",
        },
        {
          url: "https://shengzhiba.oss-cn-beijing.aliyuncs.com/lidd/4.mov",
          title: "第四集",
        },
        {
          url: "https://shengzhiba.oss-cn-beijing.aliyuncs.com/lidd/5.mov",
          title: "第五集",
        },
      ],
    };
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .video-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 将容器分为三列，每列宽度相同 */
    grid-gap: 10px; /* 可选，列之间的间隔 */
  }
}

/* 当屏幕宽度大于600px时应用的样式 */
@media screen and (min-width: 601px) {
  .video-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 将容器分为三列，每列宽度相同 */
    grid-gap: 10px; /* 可选，列之间的间隔 */
  }
}
.video-list {
  background: #f0f5fe;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
}
h1 {
  margin-bottom: 3px;
}
p {
  margin-top: 5px;
}
.video-list p {
  padding: 15px;
  margin: 0;
  text-align: left;
}
video {
  width: 100%; /* 设置视频宽度为100% */
  height: auto; /* 让浏览器根据宽高比自动计算高度 */
  max-width: 100%; /* 确保视频不会超出容器的宽度 */
  aspect-ratio: 16 / 9; /* 设置视频的宽高比为16:9 */
}
</style>
