<!--
 * @Author: lidd@belink.com
 * @Date: 2024-01-29 23:07:16
 * @LastEditors: lidd@belink.com
 * @LastEditTime: 2024-01-29 23:48:40
 * @Description: 视频
-->
<template>
  <LVideo ></LVideo>
</template>

<script>
import LVideo from './components/LVideo.vue'

export default {
  name: 'App',
  components: {
    LVideo
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
